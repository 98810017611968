export default function LoginScreen({callLogin, errorMessage}) {
    const onLoginButtonPressed = async () => {
        let username = document.getElementById("email").value;
        let password = document.getElementById("password").value;
        await callLogin(username, password);
    }

    return (
        <>
            <div className="hero min-h-screen bg-base-200">
                <div className="hero-content flex-col lg:flex-row-reverse">
                    <div className="text-center lg:text-left">
                        <h1 className="text-5xl font-bold">Login</h1>
                        <p className="py-6">Please use the pilot credentials provided to you by MSI team.</p>
                        <p className="py-6 text-error">{errorMessage}</p>
                    </div>
                    <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
                        <form className="card-body">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Email</span>
                                </label>
                                <input type="text" id="email" placeholder="email" autoComplete="username" className="input input-bordered" />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Password</span>
                                </label>
                                <input type="password" id="password" placeholder="password" autoComplete="current-password" className="input input-bordered" />
                            </div>
                            <div className="form-control mt-6">
                                <button className="btn btn-primary" onClick={async (e) => {
                                    e.preventDefault();
                                    await onLoginButtonPressed();
                                }}>Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
