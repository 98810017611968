import Player from "./Player";
import {useState, useEffect} from "react";

export default function ChatEntry({user, entry}) {
    const [expand, setExpand] = useState(false);
    useEffect(() => {
            setExpand(entry.message.length >= 20);
        },
        [entry]
    );
    const showContent = () => {
        if (entry.message.length > 20 && expand) {
            return `${entry.message.substring(0, 20)} ...`;
        }
        return entry.message;
    }
    let classNames = "card w-4/5 shadow-xl mt-2";
    if (entry.sender_name === user) {
        classNames += " bg-primary ml-1";
    } else if (entry.sender_type === "D") {
        classNames += " bg-success ml-12 text-right";
    } else {
        classNames += " bg-secondary ml-12 text-right";
    }
    return <>
        <div className={classNames}>
            <h4 className="text-sm p-2 m-2" onClick={() => {setExpand(!expand)}}>{showContent()}</h4>
            { entry.message_type === "UP" && entry.audio_link.length > 0 && <Player url={entry.audio_link} /> }
        </div>
    </>
}
