export default function UserMenu({user, signOut}) {

    return <>
        <div className="flex justify-between items-center w-full p-5">
            <div className="flex justify-center items-center">
                <img src="./logo192.png" alt="logo" className="w-10 h-10" />
                <h3 className="text-2xl">PatientConnect</h3>
            </div>
            <div className="dropdown dropdown-end">
                    <div className="avatar online placeholder">
                        <div tabIndex="0" className="bg-neutral-focus text-neutral-content rounded-full w-12">
                            <span className="text-xl">DJ</span>
                        </div>
                    </div>
                <ul tabIndex="0" className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                    <li className="w-full text-right">Hello, {user}</li>
                    <li><a href="/" onClick={signOut}><span className="w-full text-right">Log Out</span></a></li>
                </ul>
            </div>
        </div>
    </>
}
