// Helper functions for all interactions with server
// Managed only by App. App updates its state based on this helper.

export default class ServerHelper {
    static async signIn(username, password) {
        let server = process.env.REACT_APP_SERVER_URL;
        let clientID = process.env.REACT_APP_CLIENT_ID;
        let clientSecret = process.env.REACT_APP_CLIENT_SECRET;
        let data = await fetch(`${server}/o/token/`, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Basic ' + btoa(`${clientID}:${clientSecret}`),
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
            body: `grant_type=password&username=${username}&password=${password}&scope=patient`
        }).then((res) => {
            return res.json();
        }).then((resJson) => {
            if (resJson["error"]) {
                console.log("An error occurred")
                return {refreshToken: undefined, accessToken: undefined, error: resJson["error"]};
            }
            return {refreshToken: resJson.refresh_token, accessToken: resJson.access_token, error: null}
        });
        if (data.error === null) {
            data.userName = username;
            console.log("Logged in", data);
            if (data.refreshToken === undefined) {
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('accessToken');
            } else {
                localStorage.setItem('refreshToken', data.refreshToken);
                localStorage.setItem('accessToken', data.accessToken);
            }
            localStorage.setItem('userName', username);
        }
        console.log("Returning ", data);
        return data;
    }

    static async trySavedLogin() {
        let username = localStorage.getItem('username');
        let refreshToken = localStorage.getItem('refreshToken');
        let accessToken = localStorage.getItem('accessToken');
        if (username !== null && refreshToken !== null && accessToken !== null) {
            return {
                userName: username, refreshToken: refreshToken, accessToken: accessToken
            }
        }
        return null;
    }

    static async getDoctorProfile() {
        let server = process.env.REACT_APP_SERVER_URL;
        let accessToken = localStorage.getItem('accessToken');
        console.log(server, accessToken);
        let data = await fetch(`${server}/api/v1/patient/`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            })
        }).then(function (res) {
            return res.json();
        }).then(function (resJson) {
            return resJson;
        });

        console.log("getDoctorProfile", data);
        return data;
    }

    static async getAllMessages() {
        let server = process.env.REACT_APP_SERVER_URL;
        let accessToken = localStorage.getItem('accessToken');
        let data = await fetch(`${server}/api/v1/messages/`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            })
        }).then(function (res) {
            console.log("Response", res);
            return res.json();
        }).then(function (resJson) {
            console.log("JSON", resJson);
            return resJson;
        });

        console.log("getAllMessages", data);
        return data;
    }

    static async sendMessage(message) {
        let server = process.env.REACT_APP_SERVER_URL;
        let accessToken = localStorage.getItem('accessToken');
        let data = await fetch(`${server}/api/v1/messages/send/`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }),
            body: JSON.stringify({"message": message})
        }).then(function (res) {
            console.log("Response", res);
            return res.json();
        }).then(function (resJson) {
            console.log("JSON", resJson);
            return resJson;
        });

        console.log("sendMessage", data);
        return data;
    }
}
