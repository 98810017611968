import PatientScreen from "./components/PatientScreen";
import {useState, useEffect} from "react";
import ServerHelper from "./server/ServerHelper";
import LoginScreen from "./components/LoginScreen";

function App() {
    const [signInState, setSignInState] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        async function tryLogin() {
            const data = await ServerHelper.trySavedLogin();
            if (data !== null) {
                setSignInState({userName: data.userName, signedIn: true});
            }
        }
        tryLogin();
    }, [errorMessage]);   // Critical to specify error message here, otherwise login does not work

    const callLogin = async (username, password) => {
        let data = await ServerHelper.signIn(username, password);
        if (data.error === null) {
            setSignInState({userName: data.userName, signedIn: true});
        } else {
            setErrorMessage(data.error);
        }
    }

    const redirectToLogin = async () => {
        setSignInState(null);
        localStorage.removeItem('accessToken');
        setErrorMessage("Your session has expired.");
    }

    return (
        <div className="absolute w-screen h-screen flex bg-white">
            {signInState && signInState.signedIn ?
                <PatientScreen userName={signInState.userName} redirectToLogin={redirectToLogin} /> :
                <LoginScreen callLogin={callLogin} errorMessage={errorMessage}/>
            }
        </div>
    );
}

export default App;
