import {useState} from "react";
import {FaClosedCaptioning, FaRegClosedCaptioning} from "react-icons/fa";
import Player from "./Player";
import {OnlinePlayer} from "./OnlinePlayer";

export default function CareTeamTab({introText, pictureURL, audioURL}) {
    const [showText, setShowText] = useState(false);
    let className = showText ? "w-full h-52 blur" : "w-full h-52";

    return <div className="h-full flex flex-col justify-between">
        <div className="h-84 relative">
            <img className={className} src={pictureURL} alt="doctor"/>
            {showText && <div className="z-10 w-full h-full absolute top-0">
                <div className="text-sm p-5 text-justify leading-relaxed">
                    {introText}
                </div>
            </div>
            }
        </div>
        <OnlinePlayer url={audioURL} className="shrink" />
        {/*<div className="flex justify-evenly flex-end">*/}
            {/*{showText ? <FaClosedCaptioning className="text-4xl text-center" onClick={() => {*/}
            {/*    setShowText(false)*/}
            {/*}}/> : <FaRegClosedCaptioning className="text-4xl text-center" onClick={() => {*/}
            {/*    setShowText(true)*/}
            {/*}}/>}*/}
        {/*</div>*/}
    </div>
}
