import ChatEntry from "./ChatEntry";
import {RiSendPlaneFill} from "react-icons/ri";
import {useEffect} from "react";

export default function ChatTab({user, messages, sendMessage}) {
    useEffect(() =>{
        let d = document.getElementById("chat-message-container");
        d.scrollTop = d.scrollHeight;
    }, []);
    const sendChatMessage = async () => {
        let message = document.getElementById("new-chat-message").value;
        if (message.length > 0) {
            sendMessage(message);
            document.getElementById("new-chat-message").value = "";
        }
    }
    const handleEnter = async (e) => {
        if (e.key === 'Enter') {
            await sendChatMessage();
        }
    }
    return <>
        <div className="w-full h-full flex flex-col justify-between items-stretch">
            <div className="overflow-scroll h-80" id="chat-message-container">
                {messages.map((x, i) => {
                    return <ChatEntry key={x.uuid} user={user} entry={x}/>
                })}
            </div>
            <div className="form-control w-full" id="chat-send-form">
                <div className="input-group">
                    <input type="text" id="new-chat-message" placeholder="Type your question here." className="input input-bordered" onKeyDown={handleEnter} />
                    <div className="btn btn-square">
                        <RiSendPlaneFill className="text-2xl" onClick={sendChatMessage} />
                        </div>
                </div>
            </div>
        </div>
    </>
}
