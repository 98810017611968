import {useEffect, useState} from "react";
import CareTeamTab from "./CareTeamTab";
import HealthTab from "./HealthTab";
import ChatTab from "./ChatTab";
import UserMenu from "./UserMenu";
import TabSelect from "./TabSelect";
import ServerHelper from "../server/ServerHelper";


export default function PatientScreen({userName, redirectToLogin}) {
    const [introText, setIntroText] = useState("");
    const [pictureURL, setPictureURL] = useState("");
    const [audioURL, setAudioURL] = useState("");
    const [messages, setMessages] = useState([]);
    const [activeTab, setActiveTab] = useState("Care");

    useEffect(() => {
        async function getDoctorProfile() {
            let data = await ServerHelper.getDoctorProfile();
            if (data.detail !== undefined) {
                redirectToLogin()
            } else {
                setIntroText(data.profile_text);
                setPictureURL(data.picture_link);
                setAudioURL(data.audio_link);
                await checkForMessages();
            }
        }
        getDoctorProfile();
        let lastActiveTab = localStorage.getItem('ActiveTab');
        if (lastActiveTab !== null) {
            setActiveTab(lastActiveTab);
        }
    }, []);

    const sendMessage = async (message) => {
        let m = await ServerHelper.sendMessage(message);
        setMessages([...messages, m]);
    }

    const activateTab = (tab) => {
        setActiveTab(tab);
        localStorage.setItem('ActiveTab', tab);
    }

    const checkForMessages = async () => {
        console.log("Checking for messages..");
        let d2 = await ServerHelper.getAllMessages();
        if (d2.length !== messages.length) {setMessages(d2);}
    }

    return <>
        <div className="card">
            <div className="artboard artboard-demo phone-1">
                <UserMenu user={userName} signOut={redirectToLogin} />
                <div className="overflow-auto h-96 w-4/5 border-solid border-primary border-2">
                    {activeTab === "Care" && <CareTeamTab introText={introText} pictureURL={pictureURL} audioURL={audioURL} />}
                    {activeTab === "Health" && <HealthTab />}
                    {activeTab === "Chat" && <ChatTab user={userName} messages={messages} sendMessage={sendMessage} checkForMessages={checkForMessages} />}
                </div>
                <div className="tabs tabs-boxed">
                    <TabSelect activeTab={activeTab} setActiveTab={activateTab} tab="Care" title="Care Team"/>
                    <TabSelect activeTab={activeTab} setActiveTab={activateTab} tab="Health" title="Health"/>
                    <TabSelect activeTab={activeTab} setActiveTab={activateTab} tab="Chat" title="Chat"/>
                </div>
            </div>
        </div>
    </>
}
